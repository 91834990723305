import React, { useState } from "react";
import { message } from "antd";
import { marked } from "marked";
import PropTypes from "prop-types";
import { Icon, NewButton } from "../..";
import styled from "styled-components";
import { themeProp } from "../../../theme";
import isCopySupported from "../../../utils/is-copy-supported";
import useCopyClipboard from "../../../utils/use-copy-clipboard";
import { useString as s } from "../../../components/StringProvider";
import AiChatRoleNames from "../../../utils/constants/ai-chat-role-names";

const ChatControlPanel = ({ role, content, isRegenerateActive }) => {
  const copySuccess = s(
    "aiDiscoveryModal.chat.control.panel.copySuccess",
    "Response copied!"
  );
  const copyError = s(
    "aiDiscoveryModal.chat.control.panel.copyError",
    "Response failed to copy!"
  );

  const ControlNames = {
    REGENERATE: s(
      "aiDiscoveryModal.chat.control.panel.regenerate",
      "Regenerate"
    ),
    COPY: s("aiDiscoveryModal.chat.control.panel.copy", "Copy"),
    LIKE: s("aiDiscoveryModal.chat.control.panel.like", "Like"),
    DISLIKE: s("aiDiscoveryModal.chat.control.panel.dislike", "Bad response")
  };

  const controls = [
    {
      name: ControlNames.REGENERATE,
      iconName: "retry",
      active: isRegenerateActive
    },
    {
      name: ControlNames.COPY,
      iconName: "copyAll",
      active: true
    },
    {
      name: ControlNames.LIKE,
      iconName: "thumbUp",
      active: true
    },
    {
      name: ControlNames.DISLIKE,
      iconName: "thumbDown",
      active: true
    }
  ];

  const [feedBack, setFeedBack] = useState("");

  const { onCopy } = useCopyClipboard({
    showNotification: true,
    copySuccessMessage: copySuccess,
    copyFailedMessage: copyError
  });

  const handleCopyToClipboard = (content) => {
    const { ClipboardItem } = window;

    if (isCopySupported) {
      const blob = new Blob([marked(content)], {
        type: "text/html"
      });
      const data = [new ClipboardItem({ "text/html": blob })];

      navigator.clipboard
        .write(data)
        .then(() => {
          message.success(copySuccess);
        })
        .catch(() => {
          message.error(copyError);
        });
    } else {
      onCopy(content);
    }
  };

  const handleFeedback = (feedbackName) => {
    setFeedBack((prevFeedback) =>
      prevFeedback === feedbackName ? "" : feedbackName
    );
  };

  const handleControl = (controlName) => {
    switch (controlName) {
      case ControlNames.REGENERATE:
        // Call continueConversation function that sends prompt "Regenerate"
        break;
      case ControlNames.COPY:
        handleCopyToClipboard(content);
        break;
      case ControlNames.LIKE:
        handleFeedback(ControlNames.LIKE);
        break;
      case ControlNames.DISLIKE:
        handleFeedback(ControlNames.DISLIKE);
        break;
      default:
        null;
    }
  };

  if (role !== AiChatRoleNames.ASSISTANT) {
    return null;
  }

  return (
    <PanelContainer>
      {controls.map((item, index) =>
        item.active ? (
          <NewButton
            key={index}
            type={"iconDarkPrimary"}
            tooltip={item.name}
            onClick={() => handleControl(item.name)}
          >
            <StyledIcon
              name={item.iconName}
              size={"medium"}
              $isActive={feedBack == item.name}
            />
          </NewButton>
        ) : null
      )}
    </PanelContainer>
  );
};

ChatControlPanel.propTypes = {
  role: PropTypes.oneOf([AiChatRoleNames.ASSISTANT, AiChatRoleNames.USER])
    .isRequired,
  content: PropTypes.string.isRequired,
  isRegenerateActive: PropTypes.bool.isRequired
};

const PanelContainer = styled.div`
  margin-top: 20px;

  button:hover {
    svg {
      color: ${themeProp(`palette.secondary`)};
    }
  }

  button:focus {
    background-color: transparent !important;
  }
`;

const StyledIcon = styled(Icon)`
  svg {
    color: ${(props) =>
      props.$isActive
        ? themeProp(`palette.secondary`)
        : themeProp(`palette.gray4`)};
  }
`;

export default ChatControlPanel;
